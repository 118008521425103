export interface BaseFormItem {
    required: boolean;
    label: string;
    dataInfo?: string;
}

export type TextFormItem = BaseFormItem & {
    type: "text" | "textarea" | "currency" | "postalcode";
    placeHolder: string;
};

export type EmailFormItem = BaseFormItem & {
    type: "email";
    placeHolder: string;
    label: string;
    dataInfo?: string;
};

export type PhoneFormItem = BaseFormItem & {
    type: "phone";
    placeHolder: string;
};

export type DateFormItem = BaseFormItem & {
    type: "date";
    placeHolder: string;
};

export type NumberStepperFormItem = BaseFormItem & {
    type: "number";
    placeHolder: string;
    step: number;
    min: number;
};

export interface ChoicesType {
    value: string;
    label: string;
}

export type ChoiceSelectionFormItem = BaseFormItem & {
    type: "choice";
    placeHolder: string;
    choices: ChoicesType[];
};

export enum ErrorTypes {
    missing,
    invalid,
}

export type FormItem =
    | TextFormItem
    | NumberStepperFormItem
    | ChoiceSelectionFormItem
    | EmailFormItem
    | PhoneFormItem
    | DateFormItem;
