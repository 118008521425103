import { FormItem } from "./helpers/types";

export function getFormItems(t: (a: string) => string): FormItem[] {
    return [
        {
            label: t("form-question-person-name"),
            placeHolder: "John Doe",
            required: true,
            type: "text",
        },
        {
            label: t("form-question-person-date-of-birth"),
            placeHolder: "",
            required: true,
            type: "date",
        },
        {
            label: t("form-question-person-address"),
            placeHolder: "123 Rue ABC, Gatineau",
            required: true,
            type: "text",
        },
        {
            label: t("form-question-person-phone-number"),
            placeHolder: "(819) 775-0755",
            required: true,
            type: "phone",
        },
        {
            label: t("form-question-person-email"),
            placeHolder: "jclement@multi-prets.ca",
            required: true,
            type: "email",
        },
        {
            label: t("form-question-job-employer"),
            placeHolder: "Acme Inc.",
            required: true,
            type: "text",
        },
        {
            label: t("form-question-job-title"),
            placeHolder: t("form-question-job-title-placeholder"),
            required: true,
            type: "text",
        },
        {
            label: t("form-question-job-is-self-employed"),
            required: true,
            placeHolder: "",
            type: "choice",
            choices: [
                {
                    value: t("form-question-choice-yes"),
                    label: t("form-question-choice-yes"),
                },
                {
                    value: t("form-question-choice-no"),
                    label: t("form-question-choice-no"),
                },
            ],
        },
        {
            label: t("form-question-job-annual-salary"),
            placeHolder: "20 000",
            required: false,
            step: 1,
            min: 0,
            type: "number",
        },
        {
            label: t("form-question-loan-type"),
            required: true,
            placeHolder: "",
            type: "choice",
            choices: [
                {
                    value: t("form-question-loan-type-owner-occupied"),
                    label: t("form-question-loan-type-owner-occupied"),
                },
                {
                    value: t("form-question-loan-type-non-owner-occupied"),
                    label: t("form-question-loan-type-non-owner-occupied"),
                },
                {
                    value: t("form-question-loan-type-rental-property"),
                    label: t("form-question-loan-type-rental-property"),
                },
                {
                    value: t("form-question-loan-type-line-of-credit"),
                    label: t("form-question-loan-type-line-of-credit"),
                },
                {
                    value: t("form-question-loan-type-other"),
                    label: t("form-question-loan-type-other"),
                },
            ],
        },
        {
            label: t("form-question-loan-objective"),
            required: true,
            placeHolder: "",
            type: "choice",
            choices: [
                {
                    value: t("form-question-loan-objective-purchase"),
                    label: t("form-question-loan-objective-purchase"),
                },
                {
                    value: t("form-question-loan-objective-refinancing"),
                    label: t("form-question-loan-objective-refinancing"),
                },
                {
                    value: t("form-question-loan-objective-renewal"),
                    label: t("form-question-loan-objective-renewal"),
                },
            ],
        },
        {
            label: t("form-question-loan-value"),
            placeHolder: "250000",
            required: true,
            step: 1,
            min: 0,
            type: "number",
        },
    ];
}
