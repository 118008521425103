import * as React from "react";
import "./home.scss";
import { faCalculator, faPhone } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withNamespaces, WithNamespaces } from "react-i18next";
import Loader from "react-loader-spinner";
import { appSectionsPos } from "../../App";
import * as logo_en from "../../assets/img/logo_white_en.svg";
import * as logo_fr from "../../assets/img/logo_white_fr.svg";
import { parseHtmlToReactComponent } from "../../helpers/formating";

export interface HomeProps extends WithNamespaces {
  fullpageApi: any;
  hasAnimated: boolean;
  fiveVariable: string;
  fiveFixed: string;
}

class Home extends React.Component<HomeProps, undefined> {
  public render() {
    const { t } = this.props;
    const classes = ["home"];
    if (this.props.hasAnimated) {
      classes.push("has-animated");
    }

    return (
      <div>
        <div className={classes.join(" ")}>
          <div className="row">
            <div className="col s12 m12 l10">
              <div className={`home-information animated`}>
                <img alt={t("logo-alt")} className="logo" src={this.props.lng === "fr" ? logo_fr : logo_en} />
                <h1>{t("home-title")}</h1>
                <h4>{t("home-subtitle-1")}</h4>
                <div className="row social">
                  <a target="_blank" href="https://www.facebook.com/JClementMPH/">
                    <FontAwesomeIcon icon={faFacebook} />
                  </a>
                  <a target="_blank" href="https://ca.linkedin.com/in/j%C3%A9r%C3%B4me-cl%C3%A9ment-22103b105">
                    <FontAwesomeIcon icon={faLinkedin} />
                  </a>
                </div>
                <h5>{t("home-subtitle-2")}</h5>
                <p>{parseHtmlToReactComponent(t("home-description-html"))}</p>
              </div>
              <div className={`btn-container row`}>
                <div className="col s6 m3">
                  <div
                    className={`home-action-btn home-action-btn-1 waves-effect waves-light card right-aligned ${"animated"}`}
                    onClick={this.goToCalculator}
                  >
                    <FontAwesomeIcon icon={faCalculator} />
                    <span className="description">{t("home-calculate")}</span>
                  </div>
                </div>
                <div className="col s6 m3">
                  <div
                    className={`home-action-btn home-action-btn-2 waves-effect waves-light card left-aligned ${"animated"}`}
                    onClick={this.goToContact}
                  >
                    <FontAwesomeIcon icon={faPhone} />
                    <span className="description">{t("home-contact-me")}</span>
                  </div>
                </div>
                <div className="col s6 m3">
                  <div
                    className={`home-action-btn home-action-btn-3 waves-effect waves-dark card white right-aligned ${"animated"}`}
                  >
                    <span className="rate">
                      {this.props.fiveFixed ? (
                        `${this.props.fiveFixed}%`
                      ) : (
                        <Loader type="ThreeDots" color="#84bc34" height="100" width="100" />
                      )}
                    </span>
                    <span className="rate-description description">
                      <div>{t("home-fixed-rate")}</div>
                      {t("home-rate-span")}
                    </span>
                  </div>
                </div>
                <div className="col s6 m3">
                  <div className={`home-action-btn home-action-btn-4 waves-effect waves-dark card white ${"animated"}`}>
                    <span className="rate">
                      {this.props.fiveVariable ? (
                        `${this.props.fiveVariable}%`
                      ) : (
                        <Loader type="ThreeDots" color="#84bc34" height="100" width="100" />
                      )}
                    </span>
                    <span className="rate-description description">
                      <div>{t("home-variable-rate")}</div>
                      {t("home-rate-span")}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col s12 l5" />
          </div>
          <div className="agent-img animated hide-on-med-and-down" />
        </div>
      </div>
    );
  }

  public goToCalculator = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    this.props.fullpageApi.moveTo(appSectionsPos.calculator);
  };

  public goToContact = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    this.props.fullpageApi.moveTo(appSectionsPos.contact);
  };
}

export default withNamespaces()(Home);
