import { isCurrency, isEmail, isInt, isISO8601, isMobilePhone, isPostalCode } from "validator";
import { ErrorTypes, FormItem } from "./types";

export function validate(formItem: FormItem, listItemRef: ChildNode): [boolean, ErrorTypes?] {
    if (!formItem) { return [false, ErrorTypes.missing]; }

    switch (formItem.type) {
        case "text": {
            const field = (listItemRef as HTMLElement).querySelector("input") as HTMLInputElement;
            return field.value.length > 0 ? [true] : [false, ErrorTypes.invalid];
        }
        case "currency": {
            const field = (listItemRef as HTMLElement).querySelector("input") as HTMLInputElement;
            return isCurrency(field.value as string) ? [true] : [false, ErrorTypes.invalid];
        }
        case "postalcode": {
            const field = (listItemRef as HTMLElement).querySelector("input") as HTMLInputElement;
            return isPostalCode(field.value as string, "CA") ? [true] : [false, ErrorTypes.invalid];
        }
        case "email": {
            const field = (listItemRef as HTMLElement).querySelector("input") as HTMLInputElement;
            return isEmail(field.value as string) ? [true] : [false, ErrorTypes.invalid];
        }
        case "phone": {
            const field = (listItemRef as HTMLElement).querySelector("input") as HTMLInputElement;
            return isMobilePhone(field.value as string, "any") ? [true] : [false, ErrorTypes.invalid];
        }
        case "number": {
            const field = (listItemRef as HTMLElement).querySelector("input") as HTMLInputElement;
            return isInt(field.value as string) ? [true] : [false, ErrorTypes.invalid];
        }
        case "textarea": {
            const field = (listItemRef as HTMLElement).querySelector("textarea") as HTMLTextAreaElement;
            return field.value.length > 0 ? [true] : [false, ErrorTypes.invalid];
        }
        // Validation for the following fields are done through the control
        case "date":
        case "choice":
        default:
            return [true];
    }
}

export function getErrorMessage(err: ErrorTypes, formItem: FormItem, t: (a: string) => string) {
    switch (err) {
        case ErrorTypes.missing:
            return t("form-error-please-fill");
        case ErrorTypes.invalid:
            return t("form-error-invalid-" + formItem.type) + ` (e.g. ${formItem.placeHolder})`;
        default:
            return "";
    }
}
