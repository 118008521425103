import * as React from "react";

import i18n from "../../i18n";

import { withNamespaces, WithNamespaces } from "react-i18next";

import { appSectionsPos } from "../../App";
import "./navbar.scss";

export interface NavbarProps extends WithNamespaces {
  fullpageApi: any;
  isMenuOpen: boolean;
  onToggleMenu: (status: boolean) => void;
}

class Navbar extends React.Component<NavbarProps, {}> {
  public static renderPageLinks(
    t: i18n.TranslationFunction<any, object, string>,
    fullpageApi: any,
    onClick: (event?: any) => void
  ) {
    return [
      <li key="link-1">
        <a
          className="item"
          onClick={() => {
            fullpageApi.moveTo(appSectionsPos.home);
            onClick();
          }}
        >
          {t("navbar-link-home")}
        </a>
      </li>,
      <li key="link-2">
        <a
          className="item"
          onClick={() => {
            fullpageApi.moveTo(appSectionsPos.services);
            onClick();
          }}
        >
          {t("navbar-link-services")}
        </a>
      </li>,
      <li key="link-3">
        <a
          className="item"
          onClick={() => {
            fullpageApi.moveTo(appSectionsPos.calculator);
            onClick();
          }}
        >
          {t("navbar-link-calculator")}
        </a>
      </li>,
      <li key="link-4">
        <a
          className="item"
          onClick={() => {
            fullpageApi.moveTo(appSectionsPos.contact);
            onClick();
          }}
        >
          {t("navbar-link-contact")}
        </a>
      </li>,
      <li key="link-5">
        <a
          className="item"
          onClick={() => {
            i18n.changeLanguage(t("navbar-lang-val"));
            onClick();
          }}
        >
          {t("navbar-lang-link")}
        </a>
      </li>
    ];
  }
  constructor(props) {
    super(props);
  }

  public render() {
    const { t } = this.props;
    return (
      <div>
        <div className={this.addVisibleClass(["overlay"])}>
          <div className="overlay-menu">
            <ul>{Navbar.renderPageLinks(t, this.props.fullpageApi, this.toggleMenu)}</ul>
          </div>
        </div>
        <div
          className={this.addVisibleClass(["button__menu-wrapper", "z-depth-2", "hide-on-small-only"])}
          onClick={this.toggleMenu}
        >
          <div className={this.addVisibleClass(["button__menu"])}>
            <span className="top" />
            <span className="middle" />
            <span className="bottom" />
          </div>
        </div>
        <i
          onClick={this.toggleMenu}
          className="waves-effect button__menu-on-small material-icons hide-on-med-and-up show-on-small waves-light"
        >
          menu
        </i>

        {this.props.isMenuOpen ? (
          <div
            onClick={() => {
              this.toggleMenu(!this.props.isMenuOpen);
            }}
            className={"show-on-small hide-on-med-and-up button__mobile__menu-wrapper"}
          >
            <i className="medium material-icons">clear</i>
          </div>
        ) : (
          undefined
        )}
      </div>
    );
  }

  public toggleMenu = (_event: any) => {
    this.props.onToggleMenu(!this.props.isMenuOpen);
  };

  public addVisibleClass = (base: string[]): string => {
    base.push(!this.props.isMenuOpen ? "hidden" : "visible");
    return base.join(" ");
  };
}

export default {
  component: withNamespaces()(Navbar),
  class: Navbar
};
