import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { withNamespaces, WithNamespaces } from "react-i18next";
import { parseHtmlToReactComponent } from "../../helpers/formating";
import "./contact.scss";

import * as jerome from "../../assets/img/jerome.jpg";
import { MapComponent } from "../../components/map/map";

export interface ContactProps extends WithNamespaces {
  hasAnimated: Boolean;
}

class Contact extends React.Component<ContactProps, undefined> {
  public render() {
    const { t, hasAnimated } = this.props;

    const classes = ["contact"];
    if (hasAnimated) {
      classes.push("has-animated");
    }

    return (
      <div className={classes.join(" ")}>
        <div className="row head">
          <div className="col s12 m5 offset-m2 user">
            <img className="hide-on-small-only" src={jerome} alt="Jérôme Clément - CHA" />
            <h1>Jérôme Clément</h1>
          </div>

          <div className="col m4 hide-on-small-only" style={{ position: "relative" }}>
            <div className="map">
              <MapComponent />
              <h5>251, Boul.St-Joseph Gatineau, QC, Canada</h5>
            </div>
          </div>
        </div>

        <div className="row content">
          <div className="col m5 offset-m2">
            <div>
              <h4>{t("contact-courtier-agree")}</h4>
              <div className="chip">{t("contact-francais")}</div>
              <div className="chip">{t("contact-anglais")}</div>
            </div>

            <div className="divider" />

            <h3>{t("contact-communiquer-moi")}</h3>
            <p>{parseHtmlToReactComponent(t("contact-communiquer-moi-text"))}</p>
            <ul className="contact__options">
              <li className="z-depth-2">
                <div className="info__inner">
                  <i className="material-icons">local_phone</i>
                  <a href="tel:(819) 775-0755">
                    <span>(819) 775-0755</span>
                  </a>
                </div>
                <i className="material-icons">keyboard_arrow_right</i>
              </li>
              <li className="z-depth-2">
                <div className="info__inner">
                  <i className="material-icons">email</i>
                  <a href="mailto:jclement@multi-prets.ca">jclement@multi-prets.ca</a>
                </div>
                <i className="material-icons">keyboard_arrow_right</i>
              </li>
            </ul>
            <div className="row social">
              <a target="_blank" href="https://www.facebook.com/JClementMPH/">
                <FontAwesomeIcon icon={faFacebook} />
              </a>
              <a target="_blank" href="https://ca.linkedin.com/in/j%C3%A9r%C3%B4me-cl%C3%A9ment-22103b105">
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
            </div>

            <h3>{t("contact-resources")}</h3>
            <div className="divider" />
            <ul className="resources">
              <li>
                <a href={`${t("multi-prets-base-url")}${t("multi-prets-apply-now")}`}>
                  <p>{t("contact-document-demande-hyp")}</p>
                  <i className="tiny material-icons">keyboard_arrow_right</i>
                </a>
              </li>
              <li>
                <a href={`${t("multi-prets-base-url")}${t("multi-prets-rent-vs-buy")}`}>
                  <p>{t("contact-louer-ou-acheter")}</p>
                  <i className="tiny material-icons">keyboard_arrow_right</i>
                </a>
              </li>
              <li>
                <a href={`${t("multi-prets-base-url")}${t("multi-prets-first-time-buyer")}`}>
                  <p>{t("contact-premier-acheteurs")}</p>
                  <i className="tiny material-icons">keyboard_arrow_right</i>
                </a>
              </li>
              <li>
                <a href={`${t("multi-prets-base-url")}${t("multi-prets-second-property")}`}>
                  <p>{t("contact-residence-secondaire")}</p>
                  <i className="tiny material-icons">keyboard_arrow_right</i>
                </a>
              </li>
              <li>
                <a href={`${t("multi-prets-base-url")}${t("multi-prets-renew-mortgage")}`}>
                  <p>{t("contact-renouvellement-hyp")}</p>
                  <i className="tiny material-icons">keyboard_arrow_right</i>
                </a>
              </li>
              <li>
                <a href={`${t("multi-prets-base-url")}${t("multi-prets-home-retirement")}`}>
                  <p>{t("contact-votre-maison-retraite")}</p>
                  <i className="tiny material-icons">keyboard_arrow_right</i>
                </a>
              </li>
              <li>
                <a href={`${t("multi-prets-base-url")}${t("multi-prets-debt-consolidation")}`}>
                  <p>{t("contact-consolidation-dettes")}</p>
                  <i className="tiny material-icons">keyboard_arrow_right</i>
                </a>
              </li>
            </ul>
          </div>
          <div className="col m4" style={{ marginTop: "375px", justifyContent: "center", display: "flex" }}>
            <div
              className="fb-page"
              data-href="https://www.facebook.com/JClementMPH/"
              data-tabs="timeline"
              data-width=""
              data-height=""
              data-small-header="false"
              data-adapt-container-width="true"
              data-hide-cover="false"
              data-show-facepile="false"
            >
              <blockquote cite="https://www.facebook.com/JClementMPH/" className="fb-xfbml-parse-ignore">
                <a href="https://www.facebook.com/JClementMPH/">Jérôme Clément - Multi-Prêts Hypothèques Gatineau</a>
              </blockquote>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withNamespaces()(Contact);
