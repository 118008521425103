import { en } from "./en";
import { fr } from "./fr";

// the translations
export default {
  en: {
    translation: en
  },
  fr: {
    translation: fr
  }
};

export interface Locale {
  "calculator-know-more": string;
  "calculator-title": string;
  "calculator-calculate": string;
  "calculator-description-1": string;
  "calculator-description-2": string;
  "calculator-description-3": string;
  "calculator-purchase-price": string;
  "calculator-down-payment": string;
  "calculator-loan-term": string;
  "calculator-amortization": string;
  "calculator-interest-rate": string;
  "calculator-monthly-payments": string;
  "calculator-legend-months": string;
  "calculator-legend-years": string;
  "calculator-balance": string;
  "calculator-principal": string;
  "calculator-interests": string;
  "calculator-details": string;
  "calculator-variable-rate": string;
  "calculator-fixed-rate": string;
  "home-title": string;
  "home-subtitle-1": string;
  "home-subtitle-2": string;
  "home-description-html": string;
  "home-calculate": string;
  "home-contact-me": string;
  "home-fixed-rate": string;
  "home-variable-rate": string;
  "home-rate-span": string;
  "logo-alt": string;
  "navbar-lang-val": string;
  "navbar-lang-link": string;
  "navbar-link-home": string;
  "navbar-link-calculator": string;
  "navbar-link-contact": string;
  "navbar-link-services": string;
  "services-title": string;
  "services-subtitle-1": string;
  "services-subtitle-2": string;
  "services-choice-title": string;
  "services-choice-description": string;
  "services-lenders-title": string;
  "services-lenders-description": string;
  "services-peace-title": string;
  "services-peace-description": string;
  "multi-prets-base-url": string;
  "multi-prets-buy": string;
  "multi-prets-refinancing": string;
  "multi-prets-pre-qualification": string;
  "multi-prets-calculator": string;
  "multi-prets-apply-now": string;
  "multi-prets-rent-vs-buy": string;
  "multi-prets-first-time-buyer": string;
  "multi-prets-second-property": string;
  "multi-prets-renew-mortgage": string;
  "multi-prets-home-retirement": string;
  "multi-prets-debt-consolidation": string;
  "contact-resources": string;
  "contact-document-demande-hyp": string;
  "contact-louer-ou-acheter": string;
  "contact-premier-acheteurs": string;
  "contact-residence-secondaire": string;
  "contact-renouvellement-hyp": string;
  "contact-votre-maison-retraite": string;
  "contact-consolidation-dettes": string;
  "contact-courtier-agree": string;
  "contact-francais": string;
  "contact-anglais": string;
  "contact-communiquer-moi": string;
  "contact-communiquer-moi-text": string;
  "footer-text": string;
  "footer-button": string;
  "footer-or": string;
  "form-info": string;
  "form-button": string;
  "form-error-please-fill": string;
  "form-error-invalid-text": string;
  "form-error-invalid-textarea": string;
  "form-error-invalid-currency": string;
  "form-error-invalid-postalcode": string;
  "form-error-invalid-email": string;
  "form-error-invalid-phone": string;
  "form-error-invalid-date": string;
  "form-error-invalid-number": string;
  "form-error-invalid-choice": string;
  "form-question-person-name": string;
  "form-question-person-address": string;
  "form-question-person-phone-number": string;
  "form-question-person-email": string;
  "form-question-person-date-of-birth": string;
  "form-question-job-employer": string;
  "form-question-job-title": string;
  "form-question-job-title-placeholder": string;
  "form-question-job-is-self-employed": string;
  "form-question-job-annual-salary": string;
  "form-question-loan-objective": string;
  "form-question-loan-objective-renewal": string;
  "form-question-loan-objective-refinancing": string;
  "form-question-loan-objective-purchase": string;
  "form-question-loan-value": string;
  "form-question-choice-yes": string;
  "form-question-choice-no": string;
  "form-question-loan-type": string;
  "form-question-loan-type-owner-occupied": string;
  "form-question-loan-type-non-owner-occupied": string;
  "form-question-loan-type-rental-property": string;
  "form-question-loan-type-line-of-credit": string;
  "form-question-loan-type-other": string;
  "form-submit-please-wait": string;
  "form-submit-success": string;
  "form-submit-error": string;
  "send-form": string;
}
