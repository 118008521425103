import { faFacebook, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { withNamespaces, WithNamespaces } from "react-i18next";
import { parseHtmlToReactComponent } from "../../helpers/formating";
import "./footer.scss";

export interface IFooterProps extends WithNamespaces {
  heightChanged: (height: number) => void;
  onFormClick: () => void;
  onMenuClick: () => void;
}

class Footer extends React.PureComponent<IFooterProps, undefined> {
  constructor(props) {
    super(props);
  }

  public handleRef = footer => {
    if (footer) {
      this.props.heightChanged(footer.scrollHeight);
    }
  };

  public render() {
    const { t } = this.props;
    return (
      <footer className="page-footer" ref={this.handleRef}>
        <div className="row content">
          <div className="col m12 reach">
            <a onClick={this.props.onFormClick} className="waves-effect waves-light btn startRequest">
              {t("footer-button")} <i className="small material-icons">keyboard_arrow_right</i>
            </a>
            <span className="phone">{t("footer-or")} (819) 775-0755</span>
          </div>
        </div>
      </footer>
    );
  }
}

export default withNamespaces()(Footer);
