import React = require("react");
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from "react-google-maps";
import { compose, withProps } from "recompose";

export const MapComponent = compose(
    withProps({
        googleMapURL:
            "https://maps.googleapis.com/maps/api/js?key=AIzaSyABibKmsatSR_k4cfR1rYvmHjBoAxdWxLE&v=3.exp&libraries=geometry,drawing,places",
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `100%` }} />,
        mapElement: <div style={{ height: `100%` }} />,
    }),
    withScriptjs,
    withGoogleMap,
)((props) => (
    <GoogleMap
        defaultZoom={15}
        defaultOptions={{
            styles: [
                {
                    featureType: "poi",
                    elementType: "labels.icon",
                    stylers: [
                        {
                            visibility: "off",
                        },
                    ],
                },
            ],
            streetViewControl: false,
            scaleControl: false,
            mapTypeControl: false,
            panControl: false,
            zoomControl: false,
            rotateControl: false,
            fullscreenControl: false,
        }}
        defaultCenter={{ lat: 45.4363768, lng: -75.7333061 }}
    >
        <Marker position={{ lat: 45.4363768, lng: -75.7333061 }} />
    </GoogleMap>
));
