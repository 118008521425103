import * as React from "react";
import { withNamespaces, WithNamespaces } from "react-i18next";

export interface INavigationControlsProps extends WithNamespaces {
  dotsRequired: number;
  currentIndex: number;
  onDotClick: (index: number) => void;
  onContinueClick: () => void;
}

class NavigationControls extends React.Component<INavigationControlsProps, undefined> {
  constructor(props) {
    super(props);
  }

  public render() {
    const { t } = this.props;
    return (
      <div className={`fs-controls`}>
        <button onClick={this.props.onContinueClick} className={`fs-continue`}>
          {t("form-button")}
        </button>
        <div onClick={this.props.onContinueClick} className={`fs-continue-mobile`}>
          {t("form-button")}
        </div>

        {this.generateNavigationDots()}

        <div
          style={{ width: this.props.currentIndex * (100 / this.props.dotsRequired) + "%" }}
          className={`fs-progress`}
        />
        <div className={`fs-progress-bar`} />
      </div>
    );
  }

  public generateNavigationDots = () => {
    const dots = [];
    for (let i = 0; i < this.props.dotsRequired; ++i) {
      const onClick = () => this.props.onDotClick(i);
      dots.push(
        i === this.props.currentIndex ? (
          <button
            onClick={onClick}
            disabled={this.props.currentIndex < i}
            className={`${this.props.currentIndex === i ? "fs-dot-current" : ""}`}
          />
        ) : (
          <button disabled={true} />
        )
      );
    }

    return <div className={`fs-nav-dots`}>{dots}</div>;
  };
}

export default withNamespaces()(NavigationControls);
