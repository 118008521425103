import "./services.scss";
import * as React from "react";
import { withNamespaces, WithNamespaces } from "react-i18next";
import Slider from "react-slick";

import * as b2b from "../../assets/img/providers/white/b2b.png";
import * as chip from "../../assets/img/providers/white/chip.png";
import * as desjardins from "../../assets/img/providers/white/desjardins.png";
import * as bequitable from "../../assets/img/providers/white/equitable.png";
import * as fct from "../../assets/img/providers/white/fct.png";
import * as firstnation from "../../assets/img/providers/white/firstnation.png";
import * as genworth from "../../assets/img/providers/white/genworth.png";
import * as homebank from "../../assets/img/providers/white/homebank.png";
import * as homeequity from "../../assets/img/providers/white/homeequity.png";
import * as iam from "../../assets/img/providers/white/iam.png";
import * as lendwise from "../../assets/img/providers/white/lendwise.png";
import * as magenta from "../../assets/img/providers/white/magenta.png";
import * as mcap from "../../assets/img/providers/white/mcap.png";
import * as merix from "../../assets/img/providers/white/merix.png";
import * as nationalbank from "../../assets/img/providers/white/nationalbank.png";
import * as pentor from "../../assets/img/providers/white/pentor.png";
import * as schl from "../../assets/img/providers/white/schl.png";
import * as scotia from "../../assets/img/providers/white/scotia.png";
import * as simplicit from "../../assets/img/providers/white/simplicit.png";
import * as td from "../../assets/img/providers/white/td.png";

// TODO: Ajouter les images sur le site, mais ne pas les montrer toute suite
// SSQ assurance
// Adapt ?

export interface ServicesProps extends WithNamespaces {
  hasAnimated: Boolean;
}

export interface ServicesState {
  dots: boolean;
  arrows: boolean;
  infinite: boolean;
  speed: number;
  slidesToShow: number;
  slidesToScroll: number;
  autoplay: boolean;
  autoplaySpeed: number;
  className: string;
}

class Services extends React.Component<ServicesProps, ServicesState> {
  public state = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    className: "lenders-slider",
    dots: true,
    infinite: true,
    slidesToScroll: 2,
    slidesToShow: 4,
    speed: 500
  };

  public componentDidMount() {
    window.addEventListener("resize", () => {
      if (window.matchMedia("(min-width: 600px)").matches) {
        this.setState({ slidesToShow: 4, slidesToScroll: 4 });
      } else {
        this.setState({ slidesToShow: 2, slidesToScroll: 2 });
      }
    });
  }

  public render() {
    const { t, hasAnimated } = this.props;

    const classes = ["services", "center-align"];
    if (hasAnimated) {
      classes.push("has-animated");
    }

    return (
      <div>
        <div className={classes.join(" ")}>
          <div className="container">
            <h1>{t("services-title")}</h1>
            <h4>{t("services-subtitle-1")}</h4>
            <p>{t("services-subtitle-2")}</p>
            <div className="services__list">
              {this.addService("school", t("services-choice-title"), t("services-choice-description"))}
              {this.addService("account_balance", t("services-lenders-title"), t("services-lenders-description"))}
              {this.addService("hot_tub", t("services-peace-title"), t("services-peace-description"))}
            </div>

            <div style={{ paddingBottom: "20px" }}>
              <Slider {...this.state}>
                <img src={b2b} alt={"B2B Banque"} />
                <img src={bequitable} alt={"Banque Équitable"} />
                <img src={chip} alt={"L'hypothèque inversée CHIP"} />
                <img src={desjardins} alt={"Caisse Desjadin"} />
                <img src={fct} alt={"FCT"} />
                <img src={firstnation} alt={"Financière First National"} />
                <img src={genworth} alt={"Genworth Canada"} />
                <img src={homebank} alt={"Home Trust"} />
                <img src={homeequity} alt={"Banque HomeEquity"} />
                <img src={iam} alt={"Industrielle Alliance Groupe Financier"} />
                <img src={lendwise} alt={"Lendwise"} />
                <img src={magenta} alt={"Magenta"} />
                <img src={mcap} alt={"MCAP"} />
                <img src={merix} alt={"Financière Merix"} />
                <img src={pentor} alt={"Pentor Finance Corp."} />
                <img src={schl} alt={"Société canadienne d'hypothèques et de logement"} />
                <img src={scotia} alt={"Banque Scotia"} />
                <img src={simplicit} alt={"SimpliciT"} />
                <img src={td} alt={"Banque TD"} />
                <img src={nationalbank} alt={"Banque National"} />
              </Slider>
            </div>
          </div>
        </div>
      </div>
    );
  }

  private addService = (icon: string, title: string, description: string) => {
    const { t } = this.props;
    return (
      <div className="service grow animated">
        <i className="service__icon material-icons">{icon}</i>
        <h5 className="service__title">{t(title)}</h5>
        <p className="service__description">{t(description)}</p>
      </div>
    );
  };
}

export default withNamespaces()(Services);
