import * as React from "react";
import { DatePicker, Select } from "react-materialize";
import { FormItem } from "./types";

export function getFieldItem(item: FormItem, index: number) {
    const nextIndex = index + 1;
    const label = (
        <label key={`label-${nextIndex}`} className="fs-field-label fs-anim-upper" data-info={item.dataInfo}>
            {item.label}
        </label>
    );

    switch (item.type) {
        case "choice":
            const choicesOptionArray = [];
            item.choices.forEach((c, idx) => {
                choicesOptionArray.push(
                    <option key={`opt-${nextIndex}-${idx}-${c.value}`} value={c.value}>
                        {c.value}
                    </option>,
                );
            });

            return [
                label,
                <div key={`div-${nextIndex}`} className="input-field col s12 m6">
                    <Select name={"field"}>{choicesOptionArray}</Select>
                </div>,
            ];
        case "number":
            return [
                label,
                <input
                    key={`inpt-${nextIndex}`}
                    className="fs-mark fs-anim-lower"
                    id={`q${nextIndex}`}
                    name={"field"}
                    type="number"
                    placeholder={item.placeHolder}
                    step={item.step}
                    min={item.min}
                />,
            ];
        case "textarea":
            return [
                label,
                <textarea
                    key={`txtarea-${nextIndex}`}
                    className="fs-anim-lower"
                    id={`q${nextIndex}`}
                    name={"field"}
                    placeholder={item.placeHolder}
                    required={item.required}
                />,
            ];
        case "date":
            return [
                label,
                <div key={`div-${nextIndex}`} className="input-field col s12 m6">
                    <DatePicker
                        key={`dp-${nextIndex}`}
                        id={`q${index + 1}`}
                        name={"field"}
                        placeholder={item.placeHolder}
                        required={item.required}
                        options={{ yearRange: 100 }}
                    />
                </div>,
            ];
        case "phone":
        case "text":
        case "email":
        case "postalcode":
        case "currency":
            return [
                label,
                <input
                    key={`inpt-${nextIndex}`}
                    className="fs-anim-lower"
                    id={`q${index + 1}`}
                    name={"field"}
                    type={"text"}
                    placeholder={item.placeHolder}
                    required={item.required}
                />,
            ];
        default:
    }
}
