import { Locale } from "./index";

export const fr: Locale = {
  "calculator-title": "Calculatrice Hypothécaire",
  "calculator-calculate": "Calculer",
  "calculator-description-1":
    "Uttilisez notre calculatrice hypothécaire afin de visualiser l'impact de votre taux d'intérêts!",
  "calculator-description-2":
    "Entrez le prix de la maison que vous souhaitez acquérir afin de calculer " +
    "le montant de votre versement hypothécaire mensuel avec nos taux actuel!",
  "calculator-description-3": "Achetez votre maison de rêve, aujourd'hui!",
  "calculator-know-more": "En savoir plus",
  "calculator-purchase-price": "Prix d'achat",
  "calculator-down-payment": "Mise de fonds",
  "calculator-interest-rate": "Taux d’intérêt",
  "calculator-loan-term": "Termes  (années)",
  "calculator-amortization": "Amortissement (années)",
  "calculator-monthly-payments": "Mois",
  "calculator-legend-months": "Mois",
  "calculator-legend-years": "Année(s)",
  "calculator-balance": "Balance",
  "calculator-principal": "Capital",
  "calculator-interests": "Intérêt",
  "calculator-variable-rate": "TV",
  "calculator-fixed-rate": "TF",
  "calculator-details": "*Les calculs représenté ci-haut sont sur une base d'amortissement de 25 ans.",
  "home-title": "Jérôme Clément",
  "home-subtitle-1": "Votre courtier immobilier hypothécaire agréé",
  "home-subtitle-2": "Faites-vous plaisir en économisant!",
  "home-description-html":
    "Chez Multi-Prêts nous offrons un service <b>gratuit</b> et une expérience unique.<br/>Contacter moi et rencontrer plus de <b>25 partenaire d'affaire</b> en un seul rendez-vous!",
  "home-calculate": "Calculer",
  "home-contact-me": "Contactez-moi",
  "home-fixed-rate": "Taux Fixe",
  "home-variable-rate": "Taux Variable",
  "home-rate-span": "5 ans",
  "logo-alt": "Multi-Prêts - Agences Hypothécaire",
  "navbar-lang-val": "en",
  "navbar-lang-link": "English",
  "navbar-link-home": "Accueil",
  "navbar-link-calculator": "Calculateur",
  "navbar-link-contact": "Contactez Moi",
  "navbar-link-services": "Services",
  "services-title": "Mes Services Hypothécaires",
  "services-subtitle-1": "Tous nos services sont entièrement gratuits!",
  "services-subtitle-2":
    "Chez Multi-Prêts Hypothèques, s'il y a un objectif qui nous tient à cœur, c'est de vous obtenir le meilleur taux hypothécaire afin de vous faire économiser le plus d'argent possible. Une différence de 1% de taux d'intérêt se traduit souvent par des milliers de dollars d'économies!",
  "services-choice-title": "Choix Intelligent",
  "services-choice-description":
    "Faites le bon choix, lors d'un achat d'une propriété, d'un refinancement ou d'un renouvellement d'hypothèque, nous allons vous aider a trouver le produit hypothécaire qui convient à vos besoin.",
  "services-lenders-title": "Plusieurs institutions!",
  "services-lenders-description":
    "Rencontrez plus de 20 institutions financières en un seul rendez-vous, pour profitez des meilleurs taux et conditions hypothécaires",
  "services-peace-title": "Tranquilité d'esprit",
  "services-peace-description":
    "Magasinez une propriété l'esprit tranquille en vous pré qualifiant pour un montant de prêt et un taux qui peut être garanti jusqu'à 120 jours!",
  "multi-prets-base-url":
    "https://www.multi-prets.com/fr/Courtiers-Hypothecaires/site-equipe-gatineau/courtier-jerome-clement",
  "multi-prets-buy": "/Nouvel-achat",
  "multi-prets-refinancing": "/Refinancement",
  "multi-prets-pre-qualification": "/Prequalification/Formulaire/intro",
  "multi-prets-calculator": "/Outils/Calculatrices/Calculateur-de-versements-hypothecaires",
  "multi-prets-apply-now": "/Formulaires/Demande-simplifiee",
  "multi-prets-rent-vs-buy": "/Choisir-votre-hypotheque/Nouvel-achat/Acheteurs-d-une-premiere-maison/Louer-vs-acheter",
  "multi-prets-first-time-buyer": "/Choisir-votre-hypotheque/Nouvel-achat/Acheteurs-d-une-premiere-maison",
  "multi-prets-second-property": "/Choisir-votre-hypotheque/Refinancement/Residence-secondaire",
  "multi-prets-renew-mortgage": "/Choisir-votre-hypotheque/Refinancement/Renouvellement-d-hypotheque",
  "multi-prets-home-retirement": "/Choisir-votre-hypotheque/Refinancement/Retraite",
  "multi-prets-debt-consolidation": "/Choisir-votre-hypotheque/Refinancement/Regroupement-de-dettes",
  "contact-resources": "Resources",
  "contact-document-demande-hyp": "Documents requis pour votre demande d'hypothèque",
  "contact-louer-ou-acheter": "Louer ou acheter",
  "contact-premier-acheteurs": "Premiers acheteurs",
  "contact-residence-secondaire": "Résidence secondaire ou chalet",
  "contact-renouvellement-hyp": "Renouvellement d'hypothèque",
  "contact-votre-maison-retraite": "Votre maison et la retraite",
  "contact-consolidation-dettes": "Consolidation de dettes",
  "contact-courtier-agree": "Courtier immobilier hypothécaire agréé",
  "contact-francais": "Français",
  "contact-anglais": "Anglais",
  "contact-communiquer-moi": "Communiquez avec moi",
  "contact-communiquer-moi-text":
    "Magasinez une propriété l'esprit tranquille en vous pré qualifiant pour un montant de prêt et un taux qui peut être <b>garanti jusqu'à 120 jours</b>!  Que ce soit pour un refinancement ou pour l’achat d’une propriété, je vous aiderai à trouver la meilleure option possible pour votre hypothèque. En plus, mes services sont <b>gratuits</b>!",
  "footer-text": "Laissez-moi vous aidez a obtenir le meilleur taux hypothécaire grâce a mes services <b>gratuit</b>",
  "footer-button": "Commencer ma demande",
  "footer-or": "ou",
  "form-info": "Demande d'information",
  "form-button": "Suivant",
  "form-error-please-fill": "Veuillez remplir le champ ci-dessus",
  "form-error-invalid-text": "Remplir le champ avec une valeur valide",
  "form-error-invalid-textarea": "Veuillez utiliser un texte valide",
  "form-error-invalid-currency": "Veuillez utiliser un montant valide",
  "form-error-invalid-postalcode": "Veuillez utiliser un code postal valide",
  "form-error-invalid-email": "Veuillez utiliser un courriel valide",
  "form-error-invalid-phone": "Veuillez utiliser un numéro de téléphone valide",
  "form-error-invalid-date": "Veuillez utiliser une date valide",
  "form-error-invalid-number": "Veuillez utiliser un numéro valide",
  "form-error-invalid-choice": "Veuillez selectionner un choix valide",
  "form-question-person-name": "Quel est vote nom ?",
  "form-question-person-address": "Quel est votre adresse ?",
  "form-question-person-phone-number": "Quel est votre numéro de téléphone ?",
  "form-question-person-email": "Quel est votre adresse courriel ?",
  "form-question-person-date-of-birth": "Quel est votre date de naissance ?",
  "form-question-job-employer": "Quel est le nom de votre employeur ?",
  "form-question-job-title": "Quel est le titre de votre poste ?",
  "form-question-job-title-placeholder": "Professeur d'université",
  "form-question-job-is-self-employed": "Êtes-vous un travailleur autonome ?",
  "form-question-job-annual-salary": "Quel est votre salaire annuel brut ?",
  "form-question-loan-objective": "Qu'est-ce que vous cherchez ?",
  "form-question-loan-objective-renewal": "Renouvellement",
  "form-question-loan-objective-refinancing": "Refinancement",
  "form-question-loan-objective-purchase": "Achat",
  "form-question-loan-value": "Quel est le montant du prêt que vous recherchez ?",
  "form-question-choice-yes": "Oui",
  "form-question-choice-no": "Non",
  "form-question-loan-type": "Quel genre de prêt hypotécaire recherchez-vous?",
  "form-question-loan-type-owner-occupied": "Résidence propriétaire occupant",
  "form-question-loan-type-non-owner-occupied": "Résidence Secondaire",
  "form-question-loan-type-rental-property": "Résidence Locatif",
  "form-question-loan-type-line-of-credit": "Marge de crédit",
  "form-question-loan-type-other": "Autre",
  "form-submit-please-wait": "Veuillez patientez pendant que nous envoyer vos données",
  "form-submit-success": "Votre demande a été envoyer avec succès!",
  "form-submit-error": "Il semble y avoir une erreur, veuillez vérifier vos informations et réssayer de nouveau.",
  "send-form": "Envoyer"
};
