import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LegendAnchor, LegendDirection, LegendEffect, LegendItemDirection } from "@nivo/legends";
import { ResponsiveLine } from "@nivo/line";
import Checkbox from "rc-checkbox";
import * as React from "react";
import Switch from "react-switch";
import { formatMoney } from "../../helpers/formating";

const monthlyThreshold = 3;

export interface CalculatorFormProps {
  t: any;
  onValueChange: any;
  switchHandle: any;
  comparatorArray: any;
  componentKey: number;
  color: string[];
}

export class CalculatorForm extends React.Component<CalculatorFormProps, null> {
  public render() {
    const { t, componentKey, onValueChange } = this.props;
    return (
      <div className="row">
        <CalculatorTextbox
          t={t}
          onValueChanged={onValueChange}
          componentKey={componentKey}
          value={this.props.comparatorArray.purchasePriceComparator}
          labelName={"calculator-purchase-price"}
          id={"cpc"}
        />
        <CalculatorTextbox
          t={t}
          onValueChanged={onValueChange}
          componentKey={componentKey}
          value={this.props.comparatorArray.interestRateComparator}
          labelName={"calculator-interest-rate"}
          id={"irc"}
        />
        <CalculatorTextbox
          t={t}
          onValueChanged={onValueChange}
          componentKey={componentKey}
          value={this.props.comparatorArray.amortizationYearsComparator}
          labelName={"calculator-amortization"}
          id={"ayc"}
        />
        <div className="col s3">
          <div className="calculator-card">
            {this.props.comparatorArray.mortgageComparator !== undefined
              ? formatMoney(this.props.comparatorArray.mortgageComparator.getMonthlyPayment()) +
                "$ / " +
                t("calculator-monthly-payments")
              : ""}
          </div>
        </div>
        <div className="col">
          <div className="calculator-small-color-card" style={{ backgroundColor: this.props.color[componentKey] }} />
        </div>
        <div className="col">
          <label>
            <Switch
              id={"switchCheck" + componentKey}
              onChange={this.props.switchHandle}
              checked={this.props.comparatorArray.switchCheck}
            />
          </label>
        </div>
      </div>
    );
  }
}

export interface CalculatorGraphProps {
  graphData: any[];
  t: any;
  amortizationYears: number;
}

export interface CalculatorComparatorGraphProps {
  graphData: any[];
  t: any;
  color: string[];
}

// tslint:disable-next-line:max-classes-per-file
export class CalculatorComparatorGraph extends React.Component<CalculatorComparatorGraphProps, any> {
  public render() {
    return (
      <div className={"margin"} style={{ paddingRight: 50 }}>
        <div className="card card-graph-slide2">
          <ResponsiveLine
            data={this.props.graphData}
            margin={{
              top: 10,
              right: 15,
              bottom: 75,
              left: 80
            }}
            xScale={{
              type: "point"
            }}
            yScale={{
              type: "linear",
              min: "auto",
              max: "auto"
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: this.props.t("calculator-legend-years"),
              legendOffset: 36,
              legendPosition: "middle"
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              format: d => formatMoney(d) + "$"
            }}
            colors={this.props.color}
            curve="monotoneX"
            lineWidth={6}
            dotSize={10}
            dotColor="inherit:darker(0.3)"
            dotBorderWidth={2}
            dotBorderColor="#ffffff"
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            enableArea={true}
            areaOpacity={0.07}
            tooltipFormat={d => formatMoney(d) + "$"}
            legends={[
              {
                anchor: "bottom-right" as LegendAnchor,
                direction: "row" as LegendDirection,
                justify: false,
                translateX: 0,
                translateY: 60,
                itemsSpacing: 15,
                itemDirection: "left-to-right" as LegendItemDirection,
                itemWidth: 117,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: "circle",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemBackground: "rgba(0, 0, 0, .03)",
                      itemOpacity: 1
                    }
                  } as LegendEffect
                ]
              }
            ]}
          />
        </div>
      </div>
    );
  }
}

// tslint:disable-next-line:max-classes-per-file
export class CalculatorGraph extends React.Component<CalculatorGraphProps, null> {
  public render() {
    const t = this.props.t;
    return (
      <div className="col m12 l6 xl8 container container-right">
        <div className="card card-graph">
          <ResponsiveLine
            data={this.props.graphData}
            margin={{
              top: 10,
              right: 15,
              bottom: 75,
              left: 80
            }}
            xScale={{
              type: "point"
            }}
            yScale={{
              type: "linear",
              min: "auto",
              max: "auto"
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend:
                this.props.amortizationYears <= monthlyThreshold
                  ? t("calculator-legend-months")
                  : t("calculator-legend-years"),
              legendOffset: 36,
              legendPosition: "middle"
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              format: d => formatMoney(d) + "$"
            }}
            colors={["#002b55", "#84bc34", "#E57373", "#2F6AA4", "#BFEC7C", "#FFA0A0"]}
            curve="monotoneX"
            lineWidth={6}
            dotSize={10}
            dotColor="inherit:darker(0.3)"
            dotBorderWidth={2}
            dotBorderColor="#ffffff"
            animate={false}
            motionStiffness={90}
            motionDamping={15}
            enableArea={true}
            areaOpacity={0.07}
            tooltipFormat={d => formatMoney(d) + "$"}
            legends={[
              {
                anchor: "bottom-right" as LegendAnchor,
                direction: "row" as LegendDirection,
                justify: false,
                translateX: 0,
                translateY: 60,
                itemsSpacing: 15,
                itemDirection: "left-to-right" as LegendItemDirection,
                itemWidth: 117,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: "circle",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemBackground: "rgba(0, 0, 0, .03)",
                      itemOpacity: 1
                    }
                  } as LegendEffect
                ]
              }
            ]}
          />
        </div>
      </div>
    );
  }
}

export interface CalculatorCheckBoxState {
  checked: boolean;
}

export interface CalculatorCheckBoxProps {
  t: any;
  onToggleCheckbox: any;
}

// tslint:disable-next-line:max-classes-per-file
export class CalculatorCheckBox extends React.Component<CalculatorCheckBoxProps, CalculatorCheckBoxState> {
  public state = {
    checked: true
  };
  private refCBInterests: any;
  private refCBPrincipal: any;
  private refCBBalance: any;

  public render() {
    const { t, onToggleCheckbox } = this.props;
    return (
      <div className="row">
        <div className="margin">
          <div className="col">
            <label className="calculator-textBox">
              <Checkbox
                ref={checkbox => (this.refCBInterests = checkbox)}
                name="interests"
                onChange={onToggleCheckbox}
                defaultChecked={true}
              />
              {t("calculator-interests")}
            </label>
          </div>
          <div className="col">
            <label className="calculator-textBox">
              <Checkbox
                ref={checkbox => (this.refCBPrincipal = checkbox)}
                name="principal"
                onChange={onToggleCheckbox}
                defaultChecked={true}
              />
              {t("calculator-principal")}
            </label>
          </div>
          <div className="col">
            <label className="calculator-textBox">
              <Checkbox
                ref={checkbox => (this.refCBBalance = checkbox)}
                name="balance"
                onChange={onToggleCheckbox}
                defaultChecked={true}
              />
              {t("calculator-balance")}
            </label>
          </div>
        </div>
      </div>
    );
  }
}

export interface CalculatorTextboxProps {
  t: Function;
  componentKey: number;
  onValueChanged: Function;
  value: string;
  labelName: string;
  id: string;
}

export interface CalculatorTextboxState {
  value: string;
}

// tslint:disable-next-line:max-classes-per-file
class CalculatorTextbox extends React.Component<CalculatorTextboxProps, CalculatorTextboxState> {
  public state = {
    value: this.props.value
  };
  private input;

  public componentDidMount(): void {
    // loading the graph when we go on the page
    this.props.onValueChanged(this.input, this.props.componentKey);
  }

  public render() {
    const { t, labelName, componentKey, id } = this.props;

    return (
      <div className="col s4">
        <label className="calculator-textBox">{t(labelName)}</label>
        <input
          ref={input => (this.input = input)}
          name={id + componentKey}
          className="calculator-textBox"
          type="number"
          onChange={this._onValueChange}
          value={this.state.value}
        />
      </div>
    );
  }

  public _onValueChange = () => {
    if (this.input.value && this.input.value >= 1) {
      this.setState({ value: this.input.value });
      this.props.onValueChanged(this.input, this.props.componentKey);
    }
  };
}
