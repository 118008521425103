import * as React from "react";

import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HMortgage from "mortgage-helper";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import Tooltip from "rc-tooltip";
import { withNamespaces, WithNamespaces } from "react-i18next";
import SliderSlick from "react-slick";
import { formatMoney } from "../../helpers/formating";
import "./calculator.scss";
import { CalculatorCheckBox, CalculatorComparatorGraph, CalculatorForm, CalculatorGraph } from "./CalculatorComparator";

declare var M: any;

const DEFAULT_TERMS = {
  1: 0.0,
  2: 0.0,
  3: 0.0,
  4: 0.0,
  5: 0.0,
  6: 0.0,
  7: 0.0,
  10: 0.0
};

export interface CalculatorState {
  purchasePrice: number;
  amortizationYears: number;
  interestRate: string;
  graphData: any[];
  graphDataComparator: any[];
  mortgage?: HMortgage;
  mortgageVariable: HMortgage;
  slideIndex: number;
  updateCount: number;
  checkbox: boolean;
  comparatorArray: any;
  componentKey: number;
}
export interface CalculatorProps extends WithNamespaces {
  hasAnimated: Boolean;
  rates: Object;
}
const monthlyThreshold = 3;
class Calculator extends React.Component<CalculatorProps, CalculatorState> {
  public state = {
    purchasePrice: 250000,
    amortizationYears: 25,
    interestRate: undefined,
    graphData: [],
    graphDataComparator: [],
    mortgage: undefined,
    mortgageVariable: undefined,
    slideIndex: 0,
    updateCount: 0,
    checkbox: true,
    componentKey: 1,
    comparatorArray: [
      {
        id: 1,
        purchasePriceComparator: 250000,
        amortizationYearsComparator: 25,
        interestRateComparator: 2.05,
        switchCheck: true,
        mortgageComparator: undefined
      },
      {
        id: 2,
        purchasePriceComparator: 300000,
        amortizationYearsComparator: 25,
        interestRateComparator: 2.05,
        switchCheck: true,
        mortgageComparator: undefined
      }
    ]
  };
  // ref for the calculator comparator
  private sliderSlick: any;
  private checkboxInterests = { checked: true };
  private checkboxPrincipal = { checked: true };
  private checkboxBalance = { checked: true };
  private componentkey = 1;
  private colorArray = [
    "#628c26",
    "#8cc837",
    "#aed973",
    "#b34700",
    "#ff6600",
    "#ff944d",
    "#b30086",
    "#ff00bf",
    "#ff4dd2"
  ];
  private colorArrayForm = ["", "#8cc837", "#ff6600", "#ff00bf"];
  constructor(props) {
    super(props);
    this.onValueChange = this.onValueChange.bind(this);
    this.onClickChangeSlide = this.onClickChangeSlide.bind(this);
    this.switchHandleChange = this.switchHandleChange.bind(this);
    this.onToggleCheckbox = this.onToggleCheckbox.bind(this);
    this.onCalculatorComparatorValueChange = this.onCalculatorComparatorValueChange.bind(this);
  }

  public componentDidMount() {
    const elems = document.querySelectorAll(".container-dropdown select");
    M.FormSelect.init(elems);
    this.afterValueChanged();
  }

  public componentDidUpdate = prevProps => {
    if (prevProps.lng !== this.props.lng) {
      this.afterValueChanged();
    }
  };

  public switchHandleChange(checked, event, id) {
    const goodArray = this.state.comparatorArray.map(obj => {
      if (obj.id.toString() === id.slice(-1)) {
        obj.switchCheck = !obj.switchCheck;
        return obj;
      } else {
        return obj;
      }
    });
    this.setState({ comparatorArray: goodArray }, this.afterValueChangeCalculatorComparator);
  }

  public onToggleCheckbox = e => {
    const checkBox = e.target;
    if (checkBox.name === "interests") {
      this.checkboxInterests = checkBox;
    } else if (checkBox.name === "principal") {
      this.checkboxPrincipal = checkBox;
    } else if (checkBox.name === "balance") {
      this.checkboxBalance = checkBox;
    }
    this.afterValueChangeCalculatorComparator();
  };

  public onClickChangeSlide() {
    const slideIndex = this.sliderSlick;
    slideIndex.slickGoTo(1);
    this.afterValueChangeCalculatorComparator();
  }

  public onValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const element = event.currentTarget;
    if (element.name === "purchase_price") {
      const purchasePrice = Number.parseInt(element.value);
      if (isNaN(purchasePrice)) {
        return;
      }
      if (purchasePrice <= 0) {
        return;
      }
      this.setState({ purchasePrice: parseInt(element.value) }, this.afterValueChanged);
    } else if (element.name === "interest_rate") {
      this.setState({ interestRate: element.value }, this.afterValueChanged);
    }
  };

  public afterValueChanged = () => {
    if (!this.state.interestRate || isNaN(Number.parseFloat(this.state.interestRate))) {
      return;
    }

    const mortgage = new HMortgage(
      this.state.purchasePrice, // amount
      this.state.amortizationYears * 12, // installments number
      this.state.interestRate // interest rate
    );
    const amortized = mortgage.amortize();

    const mortgageVariable = new HMortgage(
      this.state.purchasePrice,
      this.state.amortizationYears * 12,
      this.props.rates[5].variable
    );
    const amortizedVariable = mortgageVariable.amortize();
    const labels = [];
    for (let i = 0; i < this.state.amortizationYears; i++) {
      labels.push(i);
    }
    const { t, hasAnimated } = this.props;

    const balance = { data: [], id: t("calculator-balance") + " " + t("calculator-fixed-rate") };
    const principal = { data: [], id: t("calculator-principal") + " " + t("calculator-fixed-rate") };
    const interests = { data: [], id: t("calculator-interests") + " " + t("calculator-fixed-rate") };
    const balanceVariable = { data: [], id: t("calculator-balance") + " " + t("calculator-variable-rate") };
    const principalVariable = { data: [], id: t("calculator-principal") + " " + t("calculator-variable-rate") };
    const interestsVariable = { data: [], id: t("calculator-interests") + " " + t("calculator-variable-rate") };
    let lastTInterests = 0;
    let lastTPrincipal = 0;
    let lastTInterestsVariable = 0;
    let lastTPrincipalVariable = 0;

    if (this.state.amortizationYears <= monthlyThreshold) {
      // We will use a per month graphic instead of a yearly
      // graphic to show more useful information
      let tInterests = 0;
      let tInterestsVariable = 0;
      let tPrincipal = 0;
      let tPrincipalVariable = 0;
      for (let i = 0; i < this.state.amortizationYears * 12; i++) {
        balance.data.push({ x: i, y: amortized.balance[i] ? Math.round(amortized.balance[i]) : 0 });

        // taux variable
        balanceVariable.data.push({
          x: i,
          y: amortizedVariable.balance[i] ? Math.round(amortizedVariable.balance[i]) : 0
        });

        tInterests = amortized.interests[i] + lastTInterests;
        lastTInterests = tInterests;

        tInterestsVariable = amortizedVariable.interests[i] + lastTInterestsVariable;
        lastTInterestsVariable = tInterestsVariable;

        tPrincipal = amortized.principal[i] + lastTPrincipal;
        lastTPrincipal = tPrincipal;

        tPrincipalVariable = amortizedVariable.principal[i] + lastTPrincipalVariable;
        lastTPrincipalVariable = tPrincipalVariable;

        interests.data.push({ x: i, y: Math.round(tInterests) });
        interests.data.push({ x: i, y: Math.round(tInterestsVariable) });

        principal.data.push({ x: i, y: Math.round(tPrincipal) });
        principalVariable.data.push({ x: i, y: Math.round(tPrincipalVariable) });
      }

      const graphData = [];
      graphData.push(balance);
      graphData.push(principal);
      graphData.push(interests);
      // variable
      graphData.push(balanceVariable);
      graphData.push(principalVariable);
      graphData.push(interestsVariable);

      this.setState({ graphData, mortgage });
      this.setState({ graphData, mortgageVariable });

      return;
    }

    for (let i = 0, x = 1; i < this.state.amortizationYears * 12; i += 12, x++) {
      balance.data.push({ x, y: amortized.balance[i + 12] ? Math.round(amortized.balance[i + 12]) : 0 });
      balanceVariable.data.push({
        x,
        y: amortizedVariable.balance[i + 12] ? Math.round(amortizedVariable.balance[i + 12]) : 0
      });

      let tInterests = 0;
      let tPrincipal = 0;
      let tInterestsVariable = 0;
      let tPrincipalVariable = 0;
      for (let y = 0; y <= 11; y++) {
        tInterests += amortized.interests[i + y];
        tPrincipal += amortized.principal[i + y];
        tInterestsVariable += amortizedVariable.interests[i + y];
        tPrincipalVariable += amortizedVariable.principal[i + y];
      }

      tInterests = tInterests + lastTInterests;
      lastTInterests = tInterests;

      tInterestsVariable = tInterestsVariable + lastTInterestsVariable;
      lastTInterestsVariable = tInterestsVariable;

      tPrincipal = tPrincipal + lastTPrincipal;
      lastTPrincipal = tPrincipal;

      tPrincipalVariable = tPrincipalVariable + lastTPrincipalVariable;
      lastTPrincipalVariable = tPrincipalVariable;

      interests.data.push({ x, y: Math.round(tInterests) });
      interestsVariable.data.push({ x, y: Math.round(tInterestsVariable) });

      principal.data.push({ x, y: Math.round(tPrincipal) });
      principalVariable.data.push({ x, y: Math.round(tPrincipalVariable) });
    }

    const graphData = [];
    graphData.push(balance);
    graphData.push(principal);
    graphData.push(interests);
    graphData.push(balanceVariable);
    graphData.push(principalVariable);
    graphData.push(interestsVariable);

    this.setState({ graphData, mortgage });
    this.setState({ graphData, mortgageVariable });
  };

  public onCalculatorComparatorValueChange = (input: any, key: number) => {
    // purchase price comparator
    if (input.name === "cpc" + key) {
      const newArray = this.state.comparatorArray.map(obj => {
        if (obj.id === key) {
          obj.purchasePriceComparator = input.value;
        }
        return obj;
      });
      this.setState({ comparatorArray: newArray });
    }
    // interest year comparator
    else if (input.name === "irc" + key) {
      const newArray = this.state.comparatorArray.map(obj => {
        if (obj.id === key) {
          obj.interestRateComparator = input.value.toString();
        }
        return obj;
      });
      this.setState({ comparatorArray: newArray });
    }
    // amortization year comparator
    else if (input.name === "ayc" + key) {
      const newArray = this.state.comparatorArray.map(obj => {
        if (obj.id === key) {
          obj.amortizationYearsComparator = input.value;
        }
        return obj;
      });
      this.setState({ comparatorArray: newArray });
    }
    const newArray = this.state.comparatorArray.map(obj => {
      if (
        !(obj.purchasePriceComparator <= 0) &&
        !(obj.amortizationYearsComparator <= 0) &&
        !(obj.interestRateComparator <= 0)
      ) {
        obj.mortgageComparator = new HMortgage(
          obj.purchasePriceComparator,
          obj.amortizationYearsComparator * 12,
          obj.interestRateComparator
        );
        return obj;
      }
    });
    if (newArray === [undefined]) {
      return;
    } else {
      this.setState({ comparatorArray: newArray });
      this.afterValueChangeCalculatorComparator();
    }
  };
  public afterValueChangeCalculatorComparator = () => {
    if (!this.state.interestRate || isNaN(Number.parseFloat(this.state.interestRate))) {
      return;
    }
    const graphDataMap = [];
    const t = this.props.t;
    let balance;
    let principal;
    let interests;
    let comparatorData;

    this.state.comparatorArray.forEach(obj => {
      comparatorData = obj;
      const amortizedComparator = comparatorData.mortgageComparator.amortize();
      // for the graph legends
      balance = { id: t("calculator-balance") + " " + obj.id, data: [] };
      principal = { id: t("calculator-principal") + " " + obj.id, data: [] };
      interests = { id: t("calculator-interests") + " " + obj.id, data: [] };
      // the calculation
      let lastTInterestsComparator1 = 0;
      let lastTPrincipalComparator1 = 0;

      for (let i = 0, x = 1; i < comparatorData.amortizationYearsComparator * 12; i += 12, x++) {
        balance.data.push({
          x,
          y: amortizedComparator.balance[i + 12] ? Math.round(amortizedComparator.balance[i + 12]) : 0
        });

        let tInterests = 0;
        let tPrincipal = 0;

        for (let y = 0; y <= 11; y++) {
          tInterests += amortizedComparator.interests[i + y];
          tPrincipal += amortizedComparator.principal[i + y];
        }

        tInterests = tInterests + lastTInterestsComparator1;
        lastTInterestsComparator1 = tInterests;

        tPrincipal = tPrincipal + lastTPrincipalComparator1;
        lastTPrincipalComparator1 = tPrincipal;

        interests.data.push({ x, y: Math.round(tInterests) });

        principal.data.push({ x, y: Math.round(tPrincipal) });
      }
      if (obj.switchCheck) {
        if (this.checkboxBalance.checked) {
          graphDataMap.push(balance);
        }
        if (this.checkboxInterests.checked) {
          graphDataMap.push(interests);
        }
        if (this.checkboxPrincipal.checked) {
          graphDataMap.push(principal);
        }
      }
    });
    this.setState({ graphDataComparator: graphDataMap });
  };

  public render() {
    if (this.props.rates && !this.state.interestRate) {
      this.setState({ interestRate: this.props.rates[5].fixed }, () => {
        this.afterValueChanged();
      });
    }
    const { t, hasAnimated } = this.props;
    const amortization = {};
    for (let i = 1; i <= 10; i++) {
      amortization[i] = i;
    }
    amortization[15] = 15;
    amortization[20] = 20;
    amortization[25] = 25;
    amortization[30] = 30;

    const classes = ["calculator"];
    if (hasAnimated) {
      classes.push("has-animated");
    }

    // Slider Slick
    const { Handle } = Slider;
    const SliderSetting = {
      arrows: false,
      autoplay: false,
      className: "row selections",
      dots: true,
      draggable: false,
      infinite: false,
      rows: 1,
      slidesPerRow: 1,
      slidesToScroll: 1,
      slidesToShow: 1,
      variableWidth: false
    };

    const handleSliderSlickRef = sliderSlick => (this.sliderSlick = sliderSlick);

    return (
      <div>
        <div className={classes.join(" ")}>
          <SliderSlick ref={handleSliderSlickRef} {...SliderSetting}>
            <div className="row selections">
              <div className="col m12 l6 xl4 container-left">
                <h1 className="calculator-title">{t("calculator-title")}</h1>
                <p className="calculator-description">{t("calculator-description-1")}</p>
                <p className="calculator-description">{t("calculator-description-2")}</p>
                <div className="calculator-container container">
                  <div className="calculator-content">
                    <div className="row">
                      <div className="col s12">
                        <div>
                          <label className="mortgageYear">{t("calculator-purchase-price")}</label>
                          <input
                            className="mortgageYear"
                            name="purchase_price"
                            type="text"
                            onChange={this.onValueChange}
                            value={this.state.purchasePrice}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col s6">
                        <div className="card mortgage card-rate">
                          <span className="rate">{this.props.rates && this.props.rates[5].fixed + "%"}</span>
                          <span className="rate-description description">
                            <div>
                              {t("home-fixed-rate")} ({t("calculator-fixed-rate")})
                            </div>
                            {t("home-rate-span")}
                          </span>
                        </div>
                      </div>
                      <div className="col s6">
                        <div className="card mortgage card-rate">
                          <span className="rate">{this.props.rates && this.props.rates[5].variable + "%"}</span>
                          <span className="rate-description description">
                            <div>
                              {t("home-variable-rate")} ({t("calculator-variable-rate")})
                            </div>
                            {t("home-rate-span")}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col s6">
                        <div className="card monthly-payments mortgage">
                          {this.state.mortgage
                            ? formatMoney(this.state.mortgage.getMonthlyPayment()) +
                              "$ / " +
                              t("calculator-monthly-payments") +
                              "*"
                            : ""}
                        </div>
                      </div>
                      <div className="col s6">
                        <div className="card monthly-payments mortgage">
                          {this.state.mortgage
                            ? formatMoney(this.state.mortgageVariable.getMonthlyPayment()) +
                              "$ / " +
                              t("calculator-monthly-payments") +
                              "*"
                            : ""}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col s12">
                        <div className="card mortgage know-more" onClick={this.onClickChangeSlide}>
                          <p style={{ display: "inline" }}>{t("calculator-know-more") + "    "}</p>
                          <FontAwesomeIcon
                            icon={faChevronRight}
                            style={{ paddingTop: 3 }}
                            // size="3x"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">{t("calculator-details")}</div>
                  </div>
                </div>
              </div>
              <CalculatorGraph
                graphData={this.state.graphData}
                t={t}
                amortizationYears={this.state.amortizationYears}
              />
            </div>
            <div>
              <div className="calculator-comparator ">
                {this.state.comparatorArray.map(obj => {
                  return (
                    <CalculatorForm
                      onValueChange={this.onCalculatorComparatorValueChange}
                      t={t}
                      comparatorArray={obj}
                      switchHandle={this.switchHandleChange}
                      componentKey={obj.id}
                      key={obj.id}
                      color={this.colorArrayForm}
                    />
                  );
                })}
              </div>
              <CalculatorComparatorGraph graphData={this.state.graphDataComparator} t={t} color={this.colorArray} />
            </div>
          </SliderSlick>
        </div>
      </div>
    );
  }
}

export default withNamespaces()(Calculator);
