const Parser = require("html-to-react").Parser;
const htmlToReactParser = new Parser();

export function formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        const i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)));
        const iString = i.toString();
        const j = iString.length > 3 ? iString.length % 3 : 0;

        return (
            negativeSign +
            (j ? iString.substr(0, j) + thousands : "") +
            iString.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
            (decimalCount
                ? decimal +
                  Math.abs(amount - i)
                      .toFixed(decimalCount)
                      .slice(2)
                : "")
        );
    } catch (e) {
        console.log(e);
    }
}

export function parseHtmlToReactComponent(value: string) {
    return htmlToReactParser.parse(value);
}
