import * as React from "react";
import { WithNamespaces, withNamespaces } from "react-i18next";
import Footer from "./components/footer/footer";
import Form, { formResults, FormState } from "./components/form/form";
import Navbar from "./components/navbar/navbar";
import Calculator from "./sections/calculator/calculator";
import Contact from "./sections/contact/contact";
import Home from "./sections/home/home";
import Services from "./sections/services/services";

const axios = require("axios");

import ReactFullpage from "@fullpage/react-fullpage";
import "fullpage.js/vendors/scrolloverflow";
import "./App.scss";
import { getTodaysRate } from "./helpers/multiprets-api";

interface AppState {
  footerHeight: number;
  loaded: number[];
  fullpageApi: any;
  contactFormStatus: FormState;
  isFormShown: boolean;
  rates: Object;
  isMenuOpen: boolean;
}
export interface AppProps extends WithNamespaces {}

export const appSectionsPos = {
  home: 1,
  services: 2,
  calculator: 3,
  contact: 4
};

class App extends React.Component<AppProps, AppState> {
  constructor(props) {
    super(props);

    this.state = {
      footerHeight: 0,
      loaded: [],
      fullpageApi: undefined,
      contactFormStatus: "filling",
      rates: undefined,
      isFormShown: false,
      isMenuOpen: false
    };

    getTodaysRate().then(r => {
      this.setState({ rates: r });
    });
  }

  public onFormClose = () => {
    this.setState({ contactFormStatus: "filling", isFormShown: false });
  };

  public onFormComplete = async (contact: { fields: formResults[]; captcha: string }) => {
    this.setState({ contactFormStatus: "sending" }, async () => {
      try {
        await axios.post("https://api.jeromeclement.ca/email/send", {
          name: contact.fields[0].value,
          email: contact.fields[4].value,
          fields: contact.fields,
          captchaResponse: contact.captcha
        });

        this.setState({ contactFormStatus: "completed" });
      } catch (error) {
        console.error(error);
        this.setState({ contactFormStatus: "error" });
      }
    });
  };

  public handleAfterLoad = origin => {
    if (origin && !this.state.loaded.includes(origin.index)) {
      const loaded = this.state.loaded;
      loaded.push(origin.index);
      this.setState({ loaded });
    }
  };

  public onMenuToggle = (menuOpen: boolean) => {
    this.setState({ isMenuOpen: menuOpen });
  };

  public getReactFullPageRender = ({ _comp, fullpageApi }) => {
    if (!this.state.fullpageApi) {
      this.setState({ fullpageApi });
    }
    return (
      <ReactFullpage.Wrapper>
        <section key="landing" id="landing" className="section">
          <Home
            fiveVariable={this.state.rates ? this.state.rates[5].variable : undefined}
            fiveFixed={this.state.rates ? this.state.rates[5].fixed : undefined}
            fullpageApi={fullpageApi}
            hasAnimated={this.state.loaded.includes(0)}
          />
        </section>
        <section key="services" id="services" className="section">
          <Services hasAnimated={this.state.loaded.includes(1)} />
        </section>
        <section key="calculate" id="calculate" className="section">
          <Calculator hasAnimated={this.state.loaded.includes(2)} rates={this.state.rates} />
        </section>
        <section key="contact" id="contact" className="section">
          <Contact hasAnimated={this.state.loaded.includes(3)} />
        </section>
      </ReactFullpage.Wrapper>
    );
  };

  public handleHeightChanges = (heightValue: number) => {
    if (heightValue !== this.state.footerHeight) {
      this.setState({ footerHeight: heightValue });
    }
  };

  public onFormClick = () => {
    this.setState({ isFormShown: true });
  };

  public onMenuClick = () => {
    this.setState({ isMenuOpen: true });
  };

  public render() {
    return (
      <div>
        <Form
          isFormOpened={this.state.isFormShown}
          formState={this.state.contactFormStatus}
          onFormCloseError={this.onFormClose}
          onFormClose={this.onFormClose}
          onFormComplete={this.onFormComplete}
        />
        <Navbar.component
          isMenuOpen={this.state.isMenuOpen}
          onToggleMenu={this.onMenuToggle}
          fullpageApi={this.state.fullpageApi}
        />
        <ReactFullpage
          licenseKey="16417A87-86154769-9FD50EEB-9FE00AC5"
          sectionSelector=".section"
          scrollOverflow={true}
          scrollOverflowOptions={{
            click: false,
            preventDefaultException: { tagName: /.*/ }
          }}
          navigation={true}
          paddingBottom={0}
          paddingTop={0}
          afterLoad={this.handleAfterLoad}
          render={this.getReactFullPageRender}
        />
        <Footer
          onFormClick={this.onFormClick}
          onMenuClick={this.onMenuClick}
          heightChanged={this.handleHeightChanges}
        />
      </div>
    );
  }
}

export default withNamespaces()(App);
