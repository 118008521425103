import * as Axios from "axios";
import convert = require("xml-js");
import { RootObject } from "./multiprets-api-types";
const axios: Axios.AxiosStatic = require("axios");

export async function getTodaysRate() {
    const response = await axios.get("https://api.jeromeclement.ca/rates/daily");
    const jsonResponse = JSON.parse(convert.xml2json(response.data, { compact: true, spaces: 4 })) as RootObject; // Conversion says it outputs a string, but it aint..

    const rates = {};
    jsonResponse.DataSet["diffgr:diffgram"].NewDataSet.Table.forEach((product) => {
        if (!rates[product.ProductTerm._text]) {
            rates[product.ProductTerm._text] = [];
        }
        rates[product.ProductTerm._text][`${product.ProductCode._text === "VC" ? "variable" : "fixed"}`] =
            product.MPH._text;
    });

    return rates;
}
