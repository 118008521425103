import { Locale } from "./index";

export const en: Locale = {
  "calculator-title": "Mortgage Calculator",
  "calculator-calculate": "Calculate",
  "calculator-description-1": "Use our mortgage calculator to see the impact of your interest rate!",
  "calculator-description-2":
    "Enter the price of the home you want to acquire, your down payment the loan term and the interest rate to calculate your mortgage payment breakdown.",
  "calculator-description-3": "Buy your dream house today!",
  "calculator-know-more": "Learn more",
  "calculator-purchase-price": "Purchase Price",
  "calculator-down-payment": "Down Payment",
  "calculator-interest-rate": "Interest Rate",
  "calculator-loan-term": "Loan Term (Years)",
  "calculator-amortization": "Amortization (Years)",
  "calculator-legend-months": "Month(s)",
  "calculator-legend-years": "Year(s)",
  "calculator-monthly-payments": "Month",
  "calculator-balance": "Balance",
  "calculator-principal": "Principal",
  "calculator-interests": "Interest",
  "calculator-variable-rate": "VR",
  "calculator-fixed-rate": "FR",
  "calculator-details": "* The calculations shown above are based on a 25-year amortization basis.",
  "home-title": "Jérôme Clément",
  "home-subtitle-1": "Your Certified Mortgage Real Estate Broker",
  "home-subtitle-2": "Treat yourself by saving!",
  "home-description-html":
    "A <b>free</b> service and a unique experience, meet more than <b>25 business partners</b> in one appointment!",
  "home-calculate": "Calculate",
  "home-contact-me": "Contact me",
  "home-fixed-rate": "Fixed Rate",
  "home-variable-rate": "Variable Rate",
  "home-rate-span": "5 years",
  "logo-alt": "Multi-Prêts - Mortgage Agency",
  "navbar-lang-val": "fr",
  "navbar-lang-link": "Français",
  "navbar-link-home": "Home",
  "navbar-link-calculator": "Calculator",
  "navbar-link-contact": "Contact Me",
  "navbar-link-services": "Services",
  "services-title": "My mortgage services",
  "services-subtitle-1": "All of our services are completly free!",
  "services-subtitle-2":
    "At Multi-Prêts Mortgages, if there is a goal that we care about, it's to get you the best mortgage rate in order to save you as much money as possible. A 1% difference in interest rates often translates into thousands of dollars in savings!",
  "services-choice-title": "Smart Choice",
  "services-choice-description":
    "Make the right choice, when buying a property, refinancing or renewing a mortgage, we will help you find the mortgage product that suits your needs.",
  "services-lenders-title": "Several institutions!",
  "services-lenders-description":
    "Meet more than 20 financial institutions in a single appointment, to take advantage of the best mortgage rates and conditions",
  "services-peace-title": "Peace of mind",
  "services-peace-description":
    "Shop a property with peace of mind by pre-qualifying for a loan amount and a rate that can be <b>guaranteed up to 120 days</b>! Whether it's for refinancing or buying a property, I will help you find the best possible option for your mortgage. In addition, my services are <b> free </b>!",
  "multi-prets-base-url": "https://www.multi-prets.com/en/Mortgage-Brokers/site-equipe-gatineau/broker-jerome-clement",
  "multi-prets-buy": "/New-purchase",
  "multi-prets-refinancing": "/Refinancing",
  "multi-prets-pre-qualification": "/Pre-qualification/Form/intro",
  "multi-prets-calculator": "/Tools/Mortgage-calculators/Mortgage-Payment-Calculator",
  "multi-prets-apply-now": "/Forms/Simplified-mortgage-request",
  "multi-prets-rent-vs-buy": "/Choose-Your-Mortgage/New-purchase/First-time-home-buyers/Rent-vs-buy",
  "multi-prets-first-time-buyer": "/Choose-Your-Mortgage/New-purchase/First-time-home-buyers",
  "multi-prets-second-property": "/Choose-Your-Mortgage/Refinancing/Second-Home",
  "multi-prets-renew-mortgage": "/Choose-Your-Mortgage/Refinancing/Mortgage-Renewal",
  "multi-prets-home-retirement": "/Choose-Your-Mortgage/Refinancing/Retirement",
  "multi-prets-debt-consolidation": "/Choose-Your-Mortgage/Refinancing/Debt-Consolidation",
  "contact-resources": "Resources",
  "contact-document-demande-hyp": "Documents required for your mortgage application",
  "contact-louer-ou-acheter": "Rent or buy",
  "contact-premier-acheteurs": "First-time buyers",
  "contact-residence-secondaire": "Secondary residence or chalet",
  "contact-renouvellement-hyp": "Renewal of mortgage",
  "contact-votre-maison-retraite": "Your home and retirement",
  "contact-consolidation-dettes": "Consolidation of debts",
  "contact-courtier-agree": "Certified Mortgage Real Estate Broker",
  "contact-francais": "French",
  "contact-anglais": "English",
  "contact-communiquer-moi": "Contact me",
  "contact-communiquer-moi-text":
    "Shop a property with peace of mind by pre-qualifying for a loan amount and a rate that can be guaranteed up to 120 days",
  "footer-text": "Let me help you get the best mortgage rate with my free services",
  "footer-button": "Start my request",
  "footer-or": "or",
  "form-info": "Information request",
  "form-button": "Continue",
  "form-error-please-fill": "Please fill the field before continuing",
  "form-error-invalid-text": "Please enter a valid value into the field",
  "form-error-invalid-textarea": "Please enter a valid value in the text field",
  "form-error-invalid-currency": "Please enter a valid monetary ammount",
  "form-error-invalid-postalcode": "Please enter a valid postal code",
  "form-error-invalid-email": "Please enter a valid email",
  "form-error-invalid-phone": "Please enter a valid phone number",
  "form-error-invalid-date": "Please enter a valid date",
  "form-error-invalid-number": "Please enter a valid number",
  "form-error-invalid-choice": "Please select a valid choice",
  "form-question-person-name": "What is your name ?",
  "form-question-person-address": "What is your home address ?",
  "form-question-person-phone-number": "What is your phone number ?",
  "form-question-person-email": "What is your email address ?",
  "form-question-person-date-of-birth": "What is your date of birth ?",
  "form-question-job-employer": "Who are you employed by ?",
  "form-question-job-title": "What is your job title ?",
  "form-question-job-title-placeholder": "University Teacher",
  "form-question-job-is-self-employed": "Are you self-employed ?",
  "form-question-job-annual-salary": "What is your annual gross salary ?",
  "form-question-loan-objective": "What are you looking for ?",
  "form-question-loan-objective-renewal": "Renewal",
  "form-question-loan-objective-refinancing": "Refinancing",
  "form-question-loan-objective-purchase": "Purchase",
  "form-question-loan-value": "What is the loan amount you are looking for ?",
  "form-question-choice-yes": "Yes",
  "form-question-choice-no": "No",
  "form-question-loan-type": " Which type of mortgage loan are you looking for?",
  "form-question-loan-type-owner-occupied": "Owner-Occupied residence",
  "form-question-loan-type-non-owner-occupied": "Non-Owner-Occupied residence",
  "form-question-loan-type-rental-property": "Rental property",
  "form-question-loan-type-line-of-credit": "Line of credit",
  "form-question-loan-type-other": "Other",
  "form-submit-please-wait": "Please wait while we send your request.",
  "form-submit-success": "Your request was successfully sent!",
  "form-submit-error":
    "There was an issue while attempting to send the information, please review the values provided and try again.",
  "send-form": "Send"
};
