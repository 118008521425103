import * as React from "react";
import Recaptcha from "react-google-invisible-recaptcha";
import { withNamespaces, WithNamespaces } from "react-i18next";
import { AppConfigs } from "../../../configs/appconfigs";
import NavigationControls from "./components/navigationControls";
import { getErrorMessage, validate } from "./helpers/errors";

import "./form.scss";
import { getFormItems } from "./formItemDefinitions";
import { getFieldItem } from "./helpers/fields";
import { FormItem } from "./helpers/types";
export type FormState = "filling" | "sending" | "completed" | "error";

export interface FormProps extends WithNamespaces {
    onFormClose: () => void;
    onFormComplete: (results: { fields: formResults[]; captcha: string }) => void;
    onFormCloseError: () => void;
    formState: FormState;
    isFormOpened: boolean;
}

export interface FormStates {
    currentStep: number;
    isLastStep: boolean;
    animationEndedIndex: number;
    errorMessage: string;
    isAnimating: boolean;
}

export interface formResults { name: string; value: string }

export class FormClass extends React.Component<FormProps, FormStates> {
    private listItemsref: HTMLOListElement = undefined;
    private formItems;
    private recaptcha;

    constructor(props) {
        super(props);

        this.formItems = getFormItems(props.t);

        this.state = this.initializeForm();
    }
    public initializeForm = () => {
        return {
            currentStep: 0,
            isLastStep: false,
            animationEndedIndex: 0,
            errorMessage: "",
            isAnimating: false,
        };
    };

    public componentDidMount = () => {
        console.log("Component did mount...");
        this.initEvents();
    };

    public render() {
        console.log("Rendering...");
        const { t } = this.props;

        const formItems = () => {
            const finalItems = [];
            this.formItems.forEach((item: FormItem, index: number) => {
                finalItems.push(
                    <li
                        className={`${this.state.currentStep === index ? "fs-current" : ""}${
                            this.state.currentStep === index &&
                            this.state.animationEndedIndex !== this.state.currentStep
                                ? " fs-show"
                                : ""
                        }`}
                        onAnimationEnd={() => this.setState({ animationEndedIndex: index, isAnimating: false })}
                    >
                        {getFieldItem(item, index)}
                        {this.state.errorMessage === "" ? null : (
                            <span className={`fs-message-error ${this.state.errorMessage ? "fs-show" : "fs-hide"}`}>
                                {this.state.errorMessage}
                            </span>
                        )}
                    </li>,
                );
            });
            return finalItems;
        };

        return (
            <div id="form-contact" className={`form-contact${this.props.isFormOpened ? " form-contact-open" : ""}`}>
                <div
                    className={`container fs-form-container${this.props.formState !== "filling" ? " noOverflow" : ""}`}
                >
                    {this.props.formState !== "filling" ? this.getLoader(t) : undefined}

                    <div className="fs-form-wrap" id="fs-form-wrap">
                        <i
                            className="material-icons form-chevron-close z-depth-2 visible"
                            onClick={(event) => {
                                this.props.onFormClose();
                            }}
                        >
                            chevron_right
                        </i>
                        <div className="fs-title">
                            <h1>{t("form-info")}</h1>
                        </div>

                        <form
                            id="myform"
                            className={`fs-form ${this.state.isLastStep ? "fs-form-overview fs-show" : "fs-form-full"}${
                                this.state.isAnimating ? " fs-hide-overflow" : ""
                            }`}
                        >
                            <ol
                                ref={(ref) => (this.listItemsref = ref)}
                                className={`fs-fields${this.state.isLastStep ? "" : " fs-display-next"}`}
                            >
                                {formItems()}
                            </ol>
                            <button
                                className="fs-submit"
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.recaptcha.execute();
                                }}
                            >
                                {t("send-form")}
                            </button>

                            {this.state.currentStep !== this.formItems.length ? null : (
                                <Recaptcha
                                    ref={(ref) => (this.recaptcha = ref)}
                                    sitekey={AppConfigs.reCaptchaSiteKey}
                                    badge="bottomleft"
                                    onResolved={() => {
                                        this.onResolved();
                                    }}
                                />
                            )}
                        </form>

                        {this.state.currentStep === this.formItems.length ? null : (
                            <NavigationControls
                                dotsRequired={this.formItems.length}
                                currentIndex={this.state.currentStep}
                                onDotClick={() => {}}
                                onContinueClick={() => {
                                    this.nextField();
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }

    public getLoader = (t: (key: string) => string) => {
        console.log("Getting Loader...");
        let loader;
        switch (this.props.formState) {
            case "error":
                loader = <div className="checkmark draw" style={{ display: "block" }} />;
                break;
            case "completed":
            case "filling":
            case "sending":
            default:
                loader = (
                    <div
                        className="checkmark draw"
                        style={this.props.formState === "completed" ? { display: "block" } : undefined}
                    />
                );
                break;
        }

        return (
            <div className="isSending">
                <div
                    className={`circle-loader${
                        this.props.formState === "completed" || this.props.formState === "error" ? " load-complete" : ""
                    }${this.props.formState === "error" ? " error" : ""}`}
                >
                    {loader}
                </div>
                <p>
                    {this.props.formState === "sending" ? t("form-submit-please-wait") : ""}
                    {this.props.formState === "completed" ? t("form-submit-success") : ""}
                    {this.props.formState === "error" ? t("form-submit-error") : ""}
                </p>

                {this.props.formState === "error" ? (
                    <button
                        className="fs-submit"
                        onClick={(e) => {
                            e.preventDefault();
                            this.recaptcha.reset();
                            this.props.onFormCloseError();
                        }}
                    >
                        Réesayer
                    </button>
                ) : (
                    undefined
                )}

                {this.props.formState === "completed" ? (
                    <button
                        className="fs-submit"
                        onClick={(e) => {
                            e.preventDefault();
                            this.props.onFormClose();
                            this.resetForm();
                        }}
                    >
                        Terminer
                    </button>
                ) : (
                    undefined
                )}
            </div>
        );
    };

    public resetForm = () => {
        this.formItems = getFormItems(this.props.t);
        this.setState(this.initializeForm());
    };

    public onResolved = async () => {
        console.log("Completing form...");
        const captcha = this.recaptcha.getResponse();
        this.props.onFormComplete({ fields: this.aggregateResults(), captcha });
    };

    public aggregateResults = () => {
        console.log("Aggregating form results...");
        const results: formResults[] = [];
        this.listItemsref.childNodes.forEach((node, i) => {
            const item = this.formItems[i];
            switch (item.type) {
                case "date":
                    const selectedDate = ((node as HTMLElement).getElementsByClassName(
                        "datepicker",
                    )[0] as any).M_Datepicker.toString();
                    results.push({ name: item.label, value: selectedDate || "Aucune valeur." });
                    break;
                case "textarea":
                    const selectedTextArea = (node as HTMLInputElement).getElementsByTagName("textarea")[0];
                    results.push({ name: item.label, value: selectedTextArea.value || "Aucune valeur." });
                    break;
                case "choice":
                default:
                    const selectedNode = (node as HTMLInputElement).getElementsByTagName("input")[0];
                    results.push({ name: item.label, value: selectedNode.value || "Aucune valeur." });
                    break;
            }
        });

        return results;
    };

    public initEvents = () => {
        console.log("Adding event listeners...");
        // Move unto the next step when pressing ENTER unless the current input element is a text area
        document.addEventListener("keydown", (ev) => {
            if (!this.state.isLastStep && (ev as any).target.tagName.toLowerCase() !== "textarea") {
                const keyCode = ev.keyCode || ev.which;
                if (keyCode === 13) {
                    ev.preventDefault();
                    this.nextField();
                }
            }
        });
    };

    public nextField = () => {
        console.log("Moving on to the next step...");
        if (this.state.isLastStep) {
            return false;
        }

        const currentFormItem: FormItem = this.formItems[this.state.currentStep];
        const currentListItemRef = this.listItemsref.childNodes[this.state.currentStep];

        const validateResult = validate(currentFormItem, currentListItemRef);
        const isLastStep = this.state.currentStep === this.formItems.length - 1;
        if (validateResult[0]) {
            this.setState({
                errorMessage: "",
                isAnimating: true,
                currentStep: this.state.currentStep + 1,
                isLastStep,
            });
        } else {
            this.setState({
                errorMessage: getErrorMessage(validateResult[1], currentFormItem, this.props.t),
            });
            return false;
        }

        // Reset the focus on the new input field unless it is of type DATE
        if (!isLastStep && this.formItems[this.state.currentStep].type !== "date") {
            ((this.listItemsref.childNodes[this.state.currentStep] as HTMLElement).querySelector(
                "input[name='field']",
            ) as HTMLElement).focus();
        }
    };
}

export default withNamespaces()(FormClass);
